import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import {
	Autocomplete,
	AppBar,
	Box,
	Button,
	Chip,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	FormControl,
	Grid,
	IconButton,
	ListItemIcon,
	Tab,
	Tabs,
	TextField,
	Toolbar,
	Typography,
	Avatar,
	Tooltip,
	Menu,
	MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import DropdownNotificaciones from "./DropdownNotificaciones";
import reporteService from "../services/ReporteService";
import usuarioService from "../services/UsuarioService";
import EmpresaService from "../services/EmpresaService";
import LoginService from "../services/LoginService";

function NavbarReporte({
	useSectionMode,
	categorias,
	categoriaActualIndex,
	onCategoriaChange,
	tituloReporte,
	anioReporte,
	refreshReporte,
	usuarioLogeado,
	rolesEditarReporte,
	mostrarSemaforos,
	determinarEstadoCategoria,
	reportesComparacion,
}) {
	const [openCollapse, setOpenCollapse] = useState(true);
	const [tabValue, setTabValue] = useState(categoriaActualIndex);
	const [openDialog, setOpenDialog] = useState(false);
	const [listaReportes, setListaReportes] = useState([]);
	const [tituloCategoria, setTituloCategoria] = useState("");
	const [nuevoTituloReporte, setNuevoTituloReporte] = useState(tituloReporte);
	const [nuevoAnioReporte, setNuevoAnioReporte] = useState(anioReporte);
	const [openEditTituloReporte, setOpenEditTituloReporte] = useState(false);
	const [reportesComparacionEdit, setReportesComparacionEdit] = useState(
		reportesComparacion || [],
	);
	const { idEmpresa, idReporte } = useParams();
	const navigate = useNavigate();
	const userName = localStorage.getItem("userName")
		? localStorage.getItem("userName")
		: "";
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const rol = localStorage.getItem("userRol")
		? usuarioService.listaRoles[localStorage.getItem("userRol")]
		: "Usuario no autorizado";

	useEffect(() => {
		reporteService
			.obtenerListaReportes(idEmpresa)
			.then((response) => response.data)
			.then((data) => {
				if (data) {
					let filtrados = data.filter((reporte) => reporte.id !== idReporte);
					setListaReportes(filtrados);
				} else {
					setListaReportes([]);
				}
			})
			.catch((error) =>
				console.error("Error al obtener la lista de reportes:", error),
			);
	}, [reportesComparacion, idEmpresa, idReporte]);

	useEffect(() => {
		setTabValue(categoriaActualIndex);
	}, [categoriaActualIndex]);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const toggleCollapse = () => {
		setOpenCollapse(!openCollapse);
	};

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
		onCategoriaChange(newValue);
	};

	const handleTituloCategoriaChange = (event) => {
		setTituloCategoria(event.target.value);
	};

	const handleAnioReporteChange = (event) => {
		setNuevoAnioReporte(event.target.value);
	};

	const handleAgregarCategoria = async () => {
		const newCategoria = {
			coordenadas: {
				indexCategoria: categorias.length,
			},
			nuevoTituloCategoria: tituloCategoria,
		};
		let nombreEmpresa = await EmpresaService.getEmpresa(idEmpresa).then(
			(response) => response.data.nombre,
		);
		let mensaje = `*${usuarioLogeado.nombre}* ha agregado la categoría *"${categorias[categoriaActualIndex]}"* en el reporte *${tituloReporte} ${anioReporte} [${nombreEmpresa}]*`;
		await reporteService.actualizarReporte(
			newCategoria,
			idReporte,
			usuarioLogeado.id,
			usuarioLogeado.nombre,
			mensaje,
		); // Se actualiza el reporte en la base de datos.
		categorias.push(tituloCategoria);
		refreshReporte();
		setOpenDialog(false);
	};

	const handleTituloReporteChange = (event) => {
		setNuevoTituloReporte(event.target.value);
	};

	// Función para guardar los cambios del titulo del reporte.
	const handleEditTituloReporte = async () => {
		let nombreEmpresa = await EmpresaService.getEmpresa(idEmpresa).then(
			(response) => response.data.nombre,
		);

		let mensaje = `*${usuarioLogeado.nombre}* ha editado el título del reporte a *"${nuevoTituloReporte} ${nuevoAnioReporte} [${nombreEmpresa}]"*`;
		await reporteService.editarTituloReporte(
			idReporte,
			nuevoTituloReporte,
			nuevoAnioReporte,
			usuarioLogeado.id,
			usuarioLogeado.nombre,
			reportesComparacionEdit,
			mensaje,
		);
		setOpenEditTituloReporte(false);
		refreshReporte();
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = async () => {
		await LoginService.logout();
	};

	const handleReporteComparacionChange = (event, newValues) => {
		setReportesComparacionEdit(newValues.map((item) => item.id)); // Guarda las ids de los reportes.
	};

	return (
		<React.Fragment>
			<AppBar position="sticky">
				<Toolbar color="primary">
					{useSectionMode && (
						<IconButton
							onClick={toggleCollapse}
							color="#003028"
							size="large"
							sx={{
								transition: "transform 0.3s",
								transform: openCollapse ? "rotateX(180deg)" : "rotateX(0deg)",
							}}
						>
							<KeyboardArrowDownIcon color="#003028" />
						</IconButton>
					)}
					<Link to="/">
						<img
							src={require("../assets/Logo 5.png")}
							alt={"Logo RIS"}
							style={{
								marginRight: 2,
								marginLeft: 4,
								width: "80px",
							}}
						/>
					</Link>
					<Box
						sx={{
							flexGrow: 1,
							display: "flex",
							justifyContent: "center",
							ml: 38,
						}}
					>
						<Typography
							variant="h5"
							component="div"
							noWrap
							sx={{
								display: { xs: "none", md: "flex" },
								p: 1,
								color: "#003028",
								fontWeight: "medium",
								fontSize: "20px",
							}}
						>
							{tituloReporte} - {anioReporte}
						</Typography>
						{usuarioLogeado &&
							rolesEditarReporte &&
							rolesEditarReporte.includes(parseInt(usuarioLogeado.rol)) && (
								<Tooltip title="Editar Reporte" placement="bottom" arrow>
									<span>
										<IconButton
											variant="outlined"
											color="#003028"
											onClick={() =>
												setOpenEditTituloReporte(!openEditTituloReporte)
											}
											sx={{
												transition: "transform 0.2s",
												mt: 0.5,
												color: "#003028",
												width: "30px",
												height: "30px",
												"&:hover": {
													transform: "scale(1.1) translateY(-2px)",
												},
											}}
										>
											<EditIcon color="white" fontSize="20px" />
										</IconButton>
									</span>
								</Tooltip>
							)}
					</Box>
					<Box sx={{ flexGrow: 1 }} />
					{usuarioLogeado ? (
						<>
							<Box
								sx={{
									display: { xs: "none", md: "flex" },
									flexDirection: "column",
									alignItems: "flex-end",
									pr: 2,
								}}
							>
								<Typography
									component="div"
									noWrap
									sx={{
										textAlign: "right",
										color: "#003028",
										fontSize: "16px",
										fontWeight: "medium",
									}}
								>
									{userName}
								</Typography>
								<Typography
									variant="h6"
									component="div"
									sx={{
										textAlign: "right",
										color: "#003028",
										fontSize: "16px",
										fontWeight: "medium",
									}}
								>
									{rol}
								</Typography>
							</Box>
							{/* Botón Notificaciones */}
							<DropdownNotificaciones usuarioLogeado={usuarioLogeado} />
							{/* Botón al final de la Navbar */}
							<Tooltip title={"Configuración"} placement="bottom" arrow>
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? "account-menu" : undefined}
									aria-haspopup="true"
									aria-expanded={open ? "true" : undefined}
								>
									<Avatar
										sx={{
											width: 32,
											height: 32,
											color: "secondary.main",
											bgcolor: "white",
											fontWeight: "bold",
										}}
									>
										{usuarioLogeado.nombre
											? usuarioLogeado.nombre.charAt(0)
											: "U"}
									</Avatar>
								</IconButton>
							</Tooltip>
						</>
					) : (
						<></>
					)}
					<Menu
						id="account-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						<MenuItem
							component={Link}
							to={`/usuarios/${usuarioLogeado?.id}`}
							onClick={handleClose}
						>
							<ListItemIcon>
								<AccountCircleIcon fontSize="small" />
							</ListItemIcon>
							Mi cuenta
						</MenuItem>
						<Divider />
						<MenuItem component={Link} to="/ajustes" onClick={handleClose}>
							<ListItemIcon>
								<SettingsIcon fontSize="small" />
							</ListItemIcon>
							Ajustes
						</MenuItem>
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<LogoutIcon fontSize="small" />
							</ListItemIcon>
							Cerrar Sesión
						</MenuItem>
					</Menu>
				</Toolbar>
				{useSectionMode && (
					<Collapse in={openCollapse} timeout="auto" unmountOnExit>
						<Box
							sx={{
								bgcolor: "white",
								borderBottom: 1,
								borderColor: "divider",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								height: "100%",
								maxHeight: "60px",
							}}
						>
							<Tabs
								value={tabValue}
								onChange={handleChange}
								variant="scrollable"
								scrollButtons="auto"
								sx={{
									flexGrow: 1,
									"& .MuiTabs-scrollButtons": { color: "black" },
									width: "100%",
									height: "100%",
									maxHeight: "60px",
									overflow: "hidden",
								}}
							>
								{categorias.map((seccion, index) => (
									<Tab
										key={index}
										value={index}
										component={Link}
										label={
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													position: "relative",
													width: "100%",
													height: "100%",
													justifyContent: "center",
													paddingRight: mostrarSemaforos ? "12px" : "0px",
													transition: "padding-right 0.3s ease",
												}}
											>
												<Typography
													noWrap
													sx={{
														display: "block",
														maxWidth: mostrarSemaforos ? "230px" : "240px",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														fontSize: "20px",
														fontWeight: "medium",
														color:
															tabValue === index
																? "primary.main"
																: "secondary.main",
														textAlign: "center",
														transition: "max-width 0.3s ease",
														position: "relative",
														left: mostrarSemaforos ? "6px" : "0px",
													}}
												>
													{seccion}
												</Typography>
												{mostrarSemaforos && (
													<Box
														sx={{
															width: 0,
															height: 0,
															borderStyle: "solid",
															borderWidth: "0 12px 12px 0",
															borderColor: `transparent ${determinarEstadoCategoria(index).color} transparent transparent`,
															position: "absolute",
															top: 2,
															right: 2,
															transition: "all 0.3s ease",
															animation: mostrarSemaforos
																? "fadeIn 0.5s"
																: "fadeOut 0.5s",
															"@keyframes fadeIn": {
																"0%": {
																	opacity: 0,
																	transform: "scale(0.5)",
																},
																"100%": {
																	opacity: 1,
																	transform: "scale(1)",
																},
															},
															"@keyframes fadeOut": {
																"0%": {
																	opacity: 1,
																	transform: "scale(1)",
																},
																"100%": {
																	opacity: 0,
																	transform: "scale(0.5)",
																},
															},
															opacity: mostrarSemaforos ? 1 : 0,
															transform: mostrarSemaforos
																? "scale(1)"
																: "scale(0.5)",
															visibility: mostrarSemaforos
																? "visible"
																: "hidden",
														}}
													/>
												)}
											</Box>
										}
										sx={{
											textTransform: "none",
											fontWeight: tabValue === index ? "bold" : "normal",
											fontSize: "20px",
											color:
												tabValue === index ? "primary.main" : "secondary.main",
											maxWidth: "250px",
											minWidth: "100px",
											height: "48px",
											padding: "0 12px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											position: "relative",
										}}
									/>
								))}
							</Tabs>
							{usuarioLogeado &&
								rolesEditarReporte &&
								rolesEditarReporte.includes(parseInt(usuarioLogeado.rol)) && (
									<Button
										variant="contained"
										onClick={() => handleOpenDialog()}
										sx={{
											textTransform: "none",
											fontWeight: "bold",
											fontSize: "15px",
											m: 0.5,
											minWidth: "250px",
											maxWidth: "350px",
											maxHeight: "40px",
										}}
										startIcon={<AddCircleOutlineIcon />}
									>
										Agregar Categoría
									</Button>
								)}
							<Button
								variant="contained"
								sx={{
									textTransform: "none",
									fontSize: "15px",
									fontWeight: "bold",
									minWidth: "100px",
									maxWidth: "150px",
									maxHeight: "40px",
									mr: 1,
								}}
								startIcon={<ArrowBackIcon />}
								onClick={() => navigate(`/empresas/${idEmpresa}/reportes`)}
							>
								Volver
							</Button>
						</Box>
					</Collapse>
				)}
			</AppBar>
			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<Typography color="primary" fontWeight="bold" sx={{ mt: 1 }}>
								Agregar Categoría
							</Typography>
						</Grid>
						<Grid
							item
							xs={4}
							container
							justifyContent="flex-end"
							sx={{ mb: 2 }}
						>
							<IconButton onClick={() => setOpenDialog(false)} disableRipple>
								<CloseIcon />
							</IconButton>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" sx={{ px: 2, mb: 2, mt: -1 }}>
								Introduzca el título de la categoría:
							</Typography>
							<Box sx={{ px: 2 }}>
								<TextField
									label="Título de la Categoría"
									variant="outlined"
									fullWidth
									value={tituloCategoria}
									onChange={(event) => handleTituloCategoriaChange(event)}
								/>
							</Box>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={12} container justifyContent="flex-end">
							<Button
								color="secondary"
								variant="text"
								onClick={() => setOpenDialog(false)}
							>
								Cancelar
							</Button>
							<Button
								color="cuaternary"
								variant="text"
								onClick={() => handleAgregarCategoria()}
							>
								Agregar
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openEditTituloReporte}
				onClose={() => setOpenEditTituloReporte(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<Typography
								variant="h5"
								color="primary"
								fontWeight="bold"
								sx={{ mt: 1 }}
							>
								Editar Título del Reporte
							</Typography>
						</Grid>
						<Grid
							item
							xs={4}
							container
							justifyContent="flex-end"
							sx={{ mb: 2 }}
						>
							<IconButton
								onClick={() => setOpenEditTituloReporte(false)}
								disableRipple
							>
								<CloseIcon />
							</IconButton>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" sx={{ px: 2, mb: 2, mt: -1 }}>
								Introduzca el nuevo título para el reporte:
							</Typography>
							<Box sx={{ px: 2 }}>
								<TextField
									label="Título del Reporte"
									variant="outlined"
									fullWidth
									defaultValue={tituloReporte}
									sx={{ mb: 2 }}
									onChange={(event) => handleTituloReporteChange(event)}
								/>

								<TextField
									label="Año del Reporte"
									variant="outlined"
									fullWidth
									defaultValue={anioReporte}
									sx={{ mb: 2 }}
									onChange={(event) => handleAnioReporteChange(event)}
								/>
								<Box>
									<FormControl fullWidth>
										<Autocomplete
											multiple
											options={listaReportes}
											getOptionLabel={(option) =>
												option.titulo + " " + option.anio
											}
											value={listaReportes.filter((opt) =>
												reportesComparacionEdit.includes(opt.id),
											)}
											onChange={handleReporteComparacionChange}
											renderTags={(tagValue, getTagProps) =>
												tagValue.map((option, index) => (
													<Chip
														key={option.id}
														label={option.titulo}
														{...getTagProps({ index })}
													/>
												))
											}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Seleccione los reportes de comparación"
													placeholder="Escriba aquí para buscar el reporte..."
												/>
											)}
										/>
									</FormControl>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={12} container justifyContent="flex-end">
							<Button
								color="secondary"
								variant="text"
								onClick={() => setOpenEditTituloReporte(false)}
							>
								Cancelar
							</Button>
							<Button
								color="cuaternary"
								variant="text"
								onClick={() => handleEditTituloReporte()}
							>
								Guardar
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

export default NavbarReporte;
