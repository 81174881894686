import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Empresas from './components/Empresas';
import Usuarios from './components/Usuarios';
import CrearUsuario from './components/CrearUsuario';
import Reporte from './components/Reporte';
import ListaReportes from './components/ListaReportes';
import CrearEmpresa from './components/CrearEmpresa';
import Login from './components/Login';
import usuarioService from './services/UsuarioService';
import PerfilUsuario from './components/PerfilUsuario';
import EditarPerfilEmpresa from './components/EditarPerfilEmpresa';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Notificaciones from './components/Notificaciones';
import VerRol from './components/VerRol';
import EditarRol from './components/EditarRol';
import AjustesUsuario from './components/AjustesUsuario';
import AgregarRol from './components/AgregarRol';
import VerEmpleados from './components/VerEmpleados';
import CrearUsuarioEmpresa from './components/CrearUsuarioEmpresa';
import "@fontsource/montserrat";
import F2AVerification from './components/F2AVerification';


const theme = createTheme({
  palette: {
    primary: {
      main: "#00BC98",
    },
    secondary: {
      main: "#006D60",
    },
    terciary: {
      main: "#FF6060",
    },
    cuaternary: {
      main: "#66BB71",
    },
    gray: {
      main: "#A4A4A4",
    },
    alt: {
      main: "#CCF2EA",
    },
    hover: {
      main: "#CCF5DE",
      navbar: "#66A7A0"
    },
  },
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: "background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out",
          fontSize: "15px",
          "&:hover": {
            filter: "brightness(100%)",
          }
        },
        contained: {
          backgroundColor: "#00BC98",
          color: "#003028",
          "&:hover": {
            backgroundColor: "#006D60",
            color: "white",
            filter: "brightness(100%)",
          }
        },
        outlined: {
          borderColor: "#00BC98",
          color: "#00BC98",
          "&:hover": {
            borderColor: "#006D60",
            color: "#006D60",
            filter: "brightness(100%)",
          }
        },
      }
    }
  }
});

function App() {

  const [usuarioLogeado, setUsuarioLogeado] = useState(null);
  const [rol, setRol] = useState(2);
  const [usuarioNuevo, setUsuarioNuevo] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const verificarUsuario = async () => {
      try {
        const response = await usuarioService.getUsuarioLogueado();
        setUsuarioLogeado(response.data);
        setRol(response.data.rol);
        setUsuarioNuevo(response.data.recienCreado ? true : false);
        localStorage.setItem('userId', response.data.id);
      } catch (error) {
        console.error('Error al obtener el usuario logueado:', error);
        setUsuarioLogeado(null);
      } finally {
        setLoading(false);
      }
    };

    verificarUsuario();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div style={{ backgroundColor: "#f0f0f0", minHeight: "100vh" }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={usuarioLogeado ? <Navigate to="/" /> : <Login />} />
            <Route path="/verify-f2a" element={usuarioLogeado ? <Navigate to="/" /> : <F2AVerification />} />
            <Route path="/forgot-password" element={usuarioLogeado ? <Navigate to="/" /> : <ForgotPassword />} />
            <Route path="/reset-password" element={
              usuarioLogeado ?
                (usuarioNuevo ? <Navigate to="/" /> : <Navigate to="/empresas" />) :
                <ResetPassword usuarioNuevo={false} usuarioLogeado={null} />
            } />
            <Route path="/" element={!usuarioLogeado ? <Navigate to="/login" /> :
              (usuarioNuevo ?
                <ResetPassword
                  usuarioNuevo={usuarioNuevo}
                  usuarioLogeado={usuarioLogeado} /> :
                usuarioLogeado.empresas.length === 1 && parseInt(rol) !== 0 ? (
                  <Navigate to={"/empresas/" + usuarioLogeado.empresas[0] + "/reportes"} />
                ) :
                  <Navigate to="/empresas" />
              )} />
            <Route path="/empresas" element={!usuarioLogeado ? <Navigate to="/login" /> : <Empresas />} />
            <Route path="/empresas/crear" element={!usuarioLogeado ? <Navigate to="/login" /> :
              (parseInt(rol) === 0 ? <CrearEmpresa /> : <Navigate to="/" />)} />
            <Route path="/empresas/:idEmpresa/reportes/:idReporte" element={!usuarioLogeado ? <Navigate to="/login" /> : <Reporte />} />
            <Route path="/empresas/:idEmpresa/reportes" element={!usuarioLogeado ? <Navigate to="/login" /> : <ListaReportes />} />
            <Route path="/empresas/:idEmpresa/crear-usuario" element={!usuarioLogeado ? <Navigate to="/login" /> : <CrearUsuarioEmpresa />} />
            <Route path="/usuarios" element={!usuarioLogeado ? <Navigate to="/login" /> :
              (parseInt(rol) === 0 ? <Usuarios /> : <Navigate to="/" />)} />
            <Route path="/usuarios/crear" element={!usuarioLogeado ? <Navigate to="/login" /> :
              (parseInt(rol) === 0 ? <CrearUsuario /> : <Navigate to="/" />)} />
            <Route path="/usuarios/:id" element={!usuarioLogeado ? <Navigate to="/login" /> : <PerfilUsuario />} />
            <Route path="/empresas/:idEmpresa" element={!usuarioLogeado ? <Navigate to="/login" /> : <EditarPerfilEmpresa />} />
            <Route path="/notificaciones" element={!usuarioLogeado ? <Navigate to="/login" /> : <Notificaciones />} />
            <Route path="/empresas/:idEmpresa/reportes/:idReporte/roles" element={!usuarioLogeado ? <Navigate to="/login" /> : <VerRol />} />
            <Route path="/empresas/:idEmpresa/reportes/:idReporte/empleados" element={!usuarioLogeado ? <Navigate to="/login" /> : <VerEmpleados />} />
            <Route path="/empresas/:idEmpresa/reportes/:idReporte/roles/agregar" element={!usuarioLogeado ? <Navigate to="/login" /> : <AgregarRol />} />
            <Route path="/empresas/:idEmpresa/reportes/:idReporte/roles/:idRol/editar" element={!usuarioLogeado ? <Navigate to="/login" /> : <EditarRol />} />
            <Route path="/ajustes" element={!usuarioLogeado ? <Navigate to="/login" /> : <AjustesUsuario />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
