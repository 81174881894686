import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography,
    Paper,
    Container,
    useTheme,
} from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import Swal from "sweetalert2";
import LoginService from "../services/LoginService";
import { useLocation, useNavigate } from "react-router-dom";

function F2AVerification() {
    const [code, setCode] = useState("");
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [isRequestingNewCode, setIsRequestingNewCode] = useState(false);

    useEffect(() => {
        if (!location.state?.userId) {
            navigate('/login');
        } else {
            setUserId(location.state.userId);
        }
    }, [location.state, navigate]);

    const handleVerification = async (event) => {
        event.preventDefault();

        if (!code) {
            Swal.fire({
                title: "Campo incompleto",
                text: "Por favor ingrese el código de verificación",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
            return;
        }

        // Mostrar Swal de carga
        Swal.fire({
            title: 'Verificando código',
            text: 'Por favor espere...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const response = await LoginService.verifyF2A(userId, code);
            if (response.token) {
                window.location.href = "/";
            } else {
                throw new Error("No se recibió el token de autenticación");
            }
        } catch (error) {
            Swal.fire({
                title: "Verificación fallida",
                text: error.message || "El código ingresado es incorrecto o ha expirado",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
        }
    };

    const handleRequestNewCode = async () => {
        if (isRequestingNewCode) return;

        setIsRequestingNewCode(true);

        Swal.fire({
            title: 'Solicitando nuevo código',
            text: 'Por favor espere...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            await LoginService.requestNewF2ACode(userId);
            Swal.close();
            Swal.fire({
                title: "Código enviado",
                text: "Se ha enviado un nuevo código a tu correo electrónico",
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
        } catch (error) {
            Swal.fire({
                title: "Lo sentimos",
                text: "No se pudo enviar un nuevo código. Por favor, intente nuevamente.",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: theme.palette.primary.main,
            });
        } finally {
            setIsRequestingNewCode(false);
        }
    };

    if (!userId) {
        return null;
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: "alt.main"
            }}
        >
            <Container maxWidth="sm">
                <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
                    <img src={require("../assets/Logo Reporte Integral.png")} style={{ width: "175px" }} alt="Logo" />
                    <LockPersonIcon
                        fontSize="large"
                        sx={{
                            fontSize: 60,
                            marginTop: 2,
                            marginBottom: 2,
                            color: "primary.main",
                        }}
                    />
                    <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 4, fontWeight: "bold" }} color="secondary">
                        Verificación en Dos Pasos
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        Verifica tu correo electrónico. Enviamos un código de autenticación con una duración de 5 minutos.
                    </Typography>
                    <form onSubmit={handleVerification}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Código de Verificación"
                                    variant="outlined"
                                    fullWidth
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    inputProps={{ maxLength: 6 }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            color: "secondary.main",
                                            background: "alt.main",
                                            backgroundColor: "alt.main",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "secondary.main",
                                            },
                                            "&.Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "secondary.main",
                                                },
                                            },
                                            "&:hover:not(.Mui-focused)": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "secondary.main",
                                                },
                                            },
                                        },
                                        "& .MuiInputLabel-outlined": {
                                            color: "secondary.main",
                                            "&.Mui-focused": {
                                                color: "secondary.main",
                                                fontWeight: "bold",
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold"
                                    }}
                                >
                                    <Typography color="white" sx={{ fontWeight: "bold" }}>Iniciar Sesión</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    component="span"
                                    onClick={handleRequestNewCode}
                                    sx={{
                                        color: "primary.main",
                                        cursor: "pointer",
                                        fontWeight: "medium",
                                        "&:hover": {
                                            textDecoration: "underline"
                                        }
                                    }}
                                >
                                    ¿No recibiste el código? Solicitar uno nuevo
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </Box>
    );
}

export default F2AVerification; 