import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const clearAuthData = () => {
    // Limpiar todos los datos de autenticación
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRol');
    localStorage.removeItem('userName');
    // Limpiar cualquier otro dato de sesión que pueda existir
    localStorage.clear();
    // Limpiar cualquier estado de axios
    delete axios.defaults.headers.common['Authorization'];
};

const login = async (email, password) => {
    try {
        // Limpiar cualquier token anterior que pueda existir
        clearAuthData();
        
        const response = await axios.post('/api/auth', {
            correo: email,
            contrasenia: password
        });

        // Si hay respuesta pero es F2A, retornamos directamente
        if (response.data.f2aRequired) {
            return response.data;
        }

        // Si no es F2A, procesamos el token
        const { token } = response.data;
        if (token) {
            const decodedToken = jwtDecode(token);
            localStorage.setItem('token', token);
            localStorage.setItem('userId', decodedToken.id);
            localStorage.setItem('userRol', decodedToken.rol);
        }

        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('Error durante el inicio de sesión. Por favor, intente nuevamente.');
        }
    }
};

const logout = () => {
    return new Promise((resolve) => {
        // Primero limpiamos los datos
        clearAuthData();
        
        // Esperamos un momento para asegurar que todo se ha limpiado
        setTimeout(() => {
            // Redirigimos y resolvemos la promesa
            window.location.replace('/login');
            resolve();
        }, 100);
    });
};

const forgotPassword = async (email) => {
    try {
        const response = await axios.post('/api/auth/forgot-password', { email });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error al procesar la solicitud');
    }
};

const validateToken = async (token, email = null) => {
    try {
        let params = { token };
        if (email) {
            params.email = email;
        }
        const response = await axios.get('/api/auth/validate-token', { params });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error al validar el token');
    }
};

const resetPassword = async (token, newPassword, email) => {
    try {
        const data = {
            token: token || 'new-user',
            newPassword,
            email
        };
        const response = await axios.put('/api/usuario/reset-password', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error al restablecer la contraseña');
    }
};

const verifyF2A = async (userId, code) => {
    try {
        const response = await axios.post('/api/auth/verify-f2a', {
            code: code,
            userId: userId
        });
        
        // Procesar el token después de la verificación F2A
        const { token } = response.data;
        if (token) {
            const decodedToken = jwtDecode(token);
            localStorage.setItem('token', token);
            localStorage.setItem('userId', decodedToken.id);
            localStorage.setItem('userRol', decodedToken.rol);
        }
        
        return response.data;
    } catch (error) {
        if (error.response?.status === 403) {
            throw new Error("El código ingresado es inválido o ha expirado");
        }
        throw new Error(error.response?.data?.message || "Error al verificar el código");
    }
};

const requestNewF2ACode = async (userId) => {
    try {
        const response = await axios.post('/api/auth/request-new-f2a', { userId });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Error al solicitar un nuevo código");
    }
};

export default {
    login,
    forgotPassword,
    validateToken,
    resetPassword,
    verifyF2A,
    logout,
    clearAuthData,
    requestNewF2ACode
};